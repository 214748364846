import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px ${theme.spacing()}px`,
    gap: theme.spacing(2),
  },

  line: {
    flex: 1,
    height: 1,
    backgroundColor: theme.palette.divider,
  }
}))

export function CommonTitle({ title }) {
  const classes = useStyles()

  return (
    <Box className={classes.title}>
      <Box className={classes.line} />
      <Typography align="center" color="textSecondary" variant="subtitle1">
        {title}
      </Typography>
      <Box className={classes.line} />
    </Box>
  )
}