import S3 from 'react-aws-s3'
import { createUUID } from '.'

export const DEFAULT_IMAGEBOSS_URL = 'https://img.imageboss.me/parseint'

// config aws
export const DEFAULT_CONFIG = {
    region: 'sa-east-1',
    bucketName: 'parseint',
    accessKeyId: process.env.REACT_APP_AWS_API_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_API_SECRET_KEY,
}

function handleFileInput(file) {
    if (!file) return

    const imageName = createUUID()
    const ext = file.name.split('.').pop()
    const newFileName = imageName + '.' + ext

    return newFileName
}

export function uploadFile(file, dirName) {
    if (!file) return

    const fileName = handleFileInput(file)
    const S3Client = new S3({ ...DEFAULT_CONFIG, dirName })

    return S3Client.uploadFile(file, fileName)
}

export function removeFile(fileName, dirName) {
    const S3Client = new S3({ ...DEFAULT_CONFIG, dirName })

    S3Client
        .deleteFile(fileName)
        .then(data => console.log('DELETED FILE', data))
        .catch(err => console.error(err))
}

export function getFileName(str = '') {
    return str.split('/').pop()
}