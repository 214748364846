import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { DEFAULT_IMAGEBOSS_URL, uploadFile } from '../../../utils/S3';

const useStyles = makeStyles((theme) => ({

  avatarLogo: {
    width: "110px",
    height: "110px",
    cursor: 'pointer',
    zIndex: 1,
    transition: '0.2s',

    "&:hover": {
      opacity: "0.5"
    }
  },

  alterarLogo: {
    width: "180px",
    height: "180px",
    cursor: 'pointer',
    zIndex: 1,

    "&:hover": {
      opacity: "0.5"
    }
  },

}))

export function DialogProfileLogo({ open, onClose, onConfirm, empresa }) {
  const classes = useStyles()
  const [file, setFile] = useState(null)
  const [saving, setSaving] = useState(false)
  const [empresaCopy, setEmpresaCopy] = useState(null)
  const [formatoLogo, setFormatoLogo] = useState('circular')

  let fileSrc = file ? URL.createObjectURL(file) : null
  fileSrc = fileSrc || empresaCopy?.urlLogo || null

  useEffect(() => {
    setEmpresaCopy({ ...empresa })
    setFormatoLogo(empresa.formatoLogo === 'square' ? 'rounded' : 'circular')
  }, [empresa, open])

  function onChangeRadioLogo(event) {
    setFormatoLogo(event.target.value)
  }

  async function uploadFileToServer(file) {
    try {
      const empresaId = empresaCopy?.id ? '/' + empresaCopy.id : ''
      const diretorio = 'qrpedir/logo' + empresaId
      const URL = DEFAULT_IMAGEBOSS_URL + '/cover:center/200x200/'

      const response = await uploadFile(file, diretorio)

      return URL + response.key
    } catch (error) {
      console.error(error)
    }
  }

  async function handleConfirm() {
    setSaving(true)

    try {
      let url = empresaCopy?.urlLogo
      if (file) {
        url = await uploadFileToServer(file)
      }

      const empresaCopyUpdated = { ...empresaCopy }

      empresaCopyUpdated.urlLogo = url
      empresaCopyUpdated.formatoLogo = formatoLogo === 'rounded' ? 'square' : 'circular'

      onConfirm(empresaCopyUpdated)
    } catch (error) {
      console.error(error)
    } finally {
      setSaving(false)
    }
  }

  function removerLogo() {
    const empresaCopyUpdated = { ...empresaCopy }
    empresaCopyUpdated.urlLogo = null

    setFile(null)
    setEmpresaCopy(empresaCopyUpdated)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}>

      <DialogTitle align="center">
        Alterar foto de perfil
      </DialogTitle>

      <DialogContent>
        <input
          type="file"
          id="fileElem"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={event => setFile(event.target.files[0])} />

        <Box display="flex" alignItems="flex-start">

          <Box display="flex" flexDirection="column" alignItems="center" flexGrow={1}>
            {fileSrc ? (
              <>
                <Avatar
                  variant={formatoLogo}
                  alt="Logo da empresa"
                  src={fileSrc}
                  className={classes.alterarLogo}
                  onClick={() => document.getElementById('fileElem').click()} />

                <Button style={{ marginTop: '8px' }} onClick={removerLogo}>
                  Remover logo
                </Button>
              </>
            ) : (
              <Avatar
                alt="Logo da empresa"
                variant={formatoLogo}
                src={fileSrc}
                className={classes.alterarLogo}
                onClick={() => document.getElementById('fileElem').click()} />
            )}
          </Box>

          <RadioGroup
            value={formatoLogo}
            onChange={onChangeRadioLogo}>
            <FormControlLabel
              value="circular"
              control={<Radio />}
              label={<AccountCircleIcon className={classes.avatarLogo} />} />
            <FormControlLabel
              value="rounded"
              control={<Radio />}
              label={<AccountBoxIcon className={classes.avatarLogo} />} />
          </RadioGroup>

        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={saving}
          color="primary"
          variant="contained"
          onClick={handleConfirm}>
          Salvar
        </Button>
      </DialogActions>

    </Dialog>
  )
}