import React, { Component } from "react";
import AuthService from "../../../AuthService";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import withStyles from "@material-ui/styles/withStyles";
import SecurityIcon from '@material-ui/icons/Security';
import { withSnackbar } from "notistack";
import "../styles.css";
import Chip from '@material-ui/core/Chip';
import { withRouter } from 'react-router-dom'
import {
  Switch,
  Grid,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  IconButton,
  Box
} from "@material-ui/core";
import { isNull } from "util";
import { CommonTitle } from "./CommonTitle";


const styles = theme => ({
  title: {
    fontSize: "120%",
    textAlign: "center",
    fontFamily: "Bebas Neue",
    textTransform: "uppercase"
  },
  subtitle: {
    fontSize: "100%",
    textAlign: "center",
    marginTop: "5px",
    fontFamily: "Bebas Neue",
    textTransform: "uppercase"
  },
  divider: {
    marginTop: "18px"
  },
  textField: {
    width: "100%"
  },
  buttonPrincipal: {
    background: "#253257",
    color: "white",
    borderRadius: 10,
    height: 48,
    width: "100%",
    marginTop: "5px",
    "&:hover": {
      background: "#E9E9E9",
      color: "#253257"
    }
  },
  cardUser: {
    marginTop: "10px"
  }
});

class formUser extends Component {
  //constructor, responsavel por chamar os metodos de contrução da classe
  //como o state que gera o estado de componete, para manter metodos como,
  //handleChange eles precisam ser intanciados dentro do contructor.

  constructor(props) {
    super(props);
    //Cada elemento de input que você terá em seu component
    //formulário assumirá o valor de state como seu valor.
    this.state = {
      users: [],
      user: {
        nome: "",
        username: "",
        password: "",
        email: "",
        ativo: true
      }
    };

    //"chamam" os metodos
    this.handleChange = this.handleChange.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAlter = this.handleAlter.bind(this);

    this.handleList = this.handleList.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEditUserAsAdmin = this.handleEditUserAsAdmin.bind(this)
  }

  blankUser() {
    return {
      nome: "",
      username: "",
      password: "",
      email: "",
      ativo: true
    };
  }

  componentDidMount() {
    this.handleList()
  }


  //responsavel por pegar os dados setados em tela pelo name do campo
  //e joga-los dentro do state
  handleChange(event) {
    const { user } = this.state;
    user[event.target.name] = event.target.value;
    this.setState({ user: user });
  }

  //handleChecked é responsavel por receber o status de checked do botão
  //status de usuário e jogar seu stauts dentro do state junto com seu name
  handleChecked(event) {
    const { user } = this.state;
    user[event.target.name] = event.target.checked;
    this.setState({ user: user });
  }

  //O handleSubmit tem sua ativação pelo button e
  //seta as informações do state para salva-las.
  handleSubmit(event) {
    const md5 = require('js-md5');
    const { user } = this.state;

    if (user.oldPass !== user.password) {
      user.password = md5(user.password);
    } else {
      console.log('NAO MUDOU A SENHA')
    }

    if (user.email === "") {
      user.email = null
    }

    //Usa o MD5 para transformar a senha em um hash

    //verifica se o id veio indefinido, vem indefinido quando
    //o item é novo, devido o id ser setado na API, entao ele
    //salva como novo usuario, caso nao seja ele apenas edita !
    let service = new AuthService();


    const loggedToken = service.getToken();
    const userToken = user.token;
    //removido empresa para evitar problema de sobreescrever status de abertura
    user.empresa = null;

    const isLoggedUser = loggedToken === userToken

    service
      .post("/usuario", user)
      .then(retorno => {

        /* SE USUARIO LOGADO ESTA SENDO EDITADO, RECUPERA O NOVO TOKEN E ATUALIZA */
        if (isLoggedUser) {
          console.log("O usuário alterou seu proprio username. Será atualizado o token", user)
          service.setToken(retorno.token)
        } else {
          console.log("O usuário NÃO alterou seu proprio username. Será atualizado o token", user)
        }

        this.handleList()
        this.setState({ user: this.blankUser() });

        this.props.enqueueSnackbar(`Usuário '${retorno.username}' salvo com sucesso`, {
          variant: "success"
        });
      })
      .catch(err => {
        if (err.response === undefined) {
          this.props.enqueueSnackbar("Falha ao tentar salvar usuário", {
            variant: "error"
          });
        } else {
          err.response.json().then(elem => {
            this.props.enqueueSnackbar(
              "Falha ao salvar usuário " + elem.errorMessage,
              { variant: "error" }
            );
          });
        }
      });
  }

  //O handleAlter pega o usuario pelo Id a partir de Callbacks
  //lista pelo id pego da classe filha
  handleAlter(user) {
    user.oldPass = user.password
    this.setState({ user: user });
  }

  handleList() {
    let service = new AuthService();
    service
      .get("/usuario/list")
      .then(retorno => {

        let usuariosSemAdmin = retorno

        /* FILTRA SE USER NAO FOR ADMIN */
        if (!service.getProfile().admin) {
          usuariosSemAdmin = retorno.filter(usuario => usuario.admin !== true)
        }

        this.setState({ users: usuariosSemAdmin });
      })
      .catch(err => {
        if (err.response === undefined) {
          this.props.enqueueSnackbar("Falha ao tentar listar usuários", {
            variant: "error"
          });
        } else {
          err.response.json().then(elem => {

            this.props.enqueueSnackbar(
              "Falha ao listar usuários " + elem.errorMessage,
              { variant: "error" }
            );
          });
        }
      });
  }

  handleDelete(user) {
    let service = new AuthService();
    service.delete(`/usuario/${user.id}`).then(retorno => {
      user.ativo = retorno.ativo;

      //ATUALIZA STATE
      let { users } = this.state
      this.setState({ users: users });

      this.props.enqueueSnackbar("Usuário inativado com sucesso", {
        variant: "success"
      });
    });
  }

  handleEditUserAsAdmin(user) {
    this.props.history.push(`/Usuario/${user.id}`)
  }

  validaForm() {
    const { user } = this.state
    let service = new AuthService();
    if (isNull(user.username) || user.username === "") {
      return true
    } else if (isNull(user.nome) || user.nome === "") {
      return true
    } else if (isNull(user.password) || user.password === "") {
      return true
    } else if (isNull(user.email) || user.email === "") {
      if (service.getProfile().admin === false) {
        return true
      }
    }
    else {
      return false
    }
  }


  render() {
    //passa a classe de style para a chamada de classes, podendo chamar
    //pelo className
    const { classes } = this.props;
    const { user } = this.state;
    const { users } = this.state;

    return (
      <>

        <form ref={this.rfmForm}>
          <Grid container spacing={2}>

            <CommonTitle title="Dados dos usuários" />

            <Grid item xs={12} sm={3} >
              <TextField
                className={classes.textField}
                variant="outlined"
                label={"Nome do usuário"}
                name="nome"
                value={user.nome}
                onChange={event => this.handleChange(event)}
              />
            </Grid>
            <Grid item xs={12} sm={3} >
              <TextField
                className={classes.textField}
                variant="outlined"
                label={"Username"}
                name="username"
                value={user.username}
                onChange={event => this.handleChange(event)}
              />
            </Grid>
            <Grid item xs={12} sm={3} >
              <TextField
                className={classes.textField}
                variant="outlined"
                label={"E-mail"}
                name="email"
                value={user.email}
                onChange={event => this.handleChange(event)}
              />
            </Grid>
            <Grid item xs={12} sm={3} >
              <TextField
                className={classes.textField}
                variant="outlined"
                label={"Senha"}
                name="password"
                type="password"
                value={user.password}
                //o prop onChange que será executado toda vez que o valor de entrada for alterado.
                onChange={event => this.handleChange(event)}
              />
            </Grid>

            <Box display='flex' flexDirection='row' justifyContent='space-between' style={{ padding: '0px 20px' }} width="100vw">
              <FormControlLabel
                name="ativo"
                control={
                  <Switch
                    checked={user.ativo}
                    color="primary"
                    onChange={event => this.handleChecked(event)}
                  />
                }
                label={"Ativo"}
              />

              <Button color="primary" variant="contained" size="small" style={{ fontFamily: "Bebas Neue", textTransform: "uppercase" }} disabled={this.validaForm()}
                onClick={e => this.handleSubmit(e)} >
                Salvar usuário
              </Button>
            </Box>

            <CommonTitle title="Usuários cadastrados" />
          </Grid>
        </form>

        <div>
          {users.map(user => (
            <Box key={user.id} style={{ borderBottom: "1px solid lightgray" }}>
              <Grid container alignItems='center'>

                <Grid item xs={12} sm={5} >
                  <Typography>
                    {user.nome}
                    {user.admin ? <Chip size="small" label="admin" color="primary" style={{ marginLeft: "10px" }} /> : null}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography>
                    {user.username}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={1}>
                  <Typography style={{ textTransform: "uppercase" }} color={user.ativo ? 'textPrimary' : 'error'}>
                    {user.ativo ? "ATIVO" : "INATIVO"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={2}>
                  <IconButton
                    edge="end"
                    onClick={() => this.handleAlter(user)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" onClick={() => this.handleDelete(user)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton edge="end" onClick={() => this.handleEditUserAsAdmin(user)}>
                    <SecurityIcon />
                  </IconButton>
                </Grid>

              </Grid>
            </Box>
          ))}
        </div>
      </>
    )
  }
}
export default withRouter(withSnackbar(withStyles(styles)(formUser)));
