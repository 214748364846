import React, { useState } from 'react'
import withStyles from '@material-ui/styles/withStyles'
import PublishIcon from '@material-ui/icons/Publish'
import { Box, Typography, CircularProgress, } from '@material-ui/core'
import { DEFAULT_IMAGEBOSS_URL, uploadFile } from '../../../utils/S3'
import clsx from 'clsx'

const styles = (theme) => ({
  cover: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundColor: '#ddd',
    backgroundPosition: 'center',
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
  },

  coverLabel: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: '0.2s',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    opacity: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      opacity: 1,
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      top: theme.spacing(1.5),
      left: theme.spacing(1.5),
      right: theme.spacing(1.5),
      bottom: theme.spacing(1.5),
      border: '3px dashed #ddd',
    },
  },

  coverLabelEmpty: {
    backgroundColor: 'transparent',
    opacity: 1,
  },

  coverLoading: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.3)',
  }
})

function CoverInput({ classes, empresa, onSubmit, onUpdateCompany }) {
  const [loading, setLoading] = useState(false)
  const URL_IMAGEBOSS = DEFAULT_IMAGEBOSS_URL + '/cover:center/1200x300/'

  async function uploadFileServer(file) {
    try {
      if (loading) return

      setLoading(true)

      const empresaId = empresa?.id ? '/' + empresa.id : ''
      const diretorio = 'qrpedir/cover' + empresaId
      const response = await uploadFile(file, diretorio)

      // caso queira deletar a capa antiga, descomente as linhas abaixo
      // mas será necessário resolver o problema de permissão lá na S3
      //
      // const fileNameToDelete = getFileName(empresa.urlCover)
      // await removeFile(fileNameToDelete, diretorio)

      empresa.urlCover = response.key

      onUpdateCompany(empresa)
      await onSubmit()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box className={classes.cover} style={{ backgroundImage: empresa.urlCover ? `url(${URL_IMAGEBOSS + empresa.urlCover})` : 'none' }} >
      {loading && (
        <Box className={classes.coverLoading}>
          <CircularProgress />
        </Box>
      )}

      <label htmlFor="labelCover" className={clsx(classes.coverLabel, !empresa.urlCover ? classes.coverLabelEmpty : null)}>
        <Box textAlign="center">
          <PublishIcon style={{ fontSize: 80 }} />
          <Typography>
            {empresa.urlCover ? 'Substituir capa' : 'Adicionar capa'}
          </Typography>
        </Box>
      </label>

      <input
        disabled={loading}
        type="file"
        id="labelCover"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={({ target }) => uploadFileServer(target.files[0])} />
    </Box>
  )
}

export default withStyles(styles)(CoverInput)