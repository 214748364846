import React, { Component } from "react"
import FormUser from "./components/formUser"
import FormEmpresa from "../Cadastro/components/formEmpresa"
import Template from "../Generico/Template"
import TituloPrincipal from "../Generico/TituloPrincipal"
import { Box, Container } from "@material-ui/core"


class DadosEmpresa extends Component {

  render() {
    document.title = 'QR Pedir - Cadastro de empresa'
    //passa a classe de style para a chamada de classes, podendo chamar
    //pelo className
    return (
      <Container>

        <Template documentTitle="Conta da empresa">
          <TituloPrincipal title="Conta da empresa" subTitle="Edite os dados da empresa ou seus usuários" />

          <Box mt={5} >
            <FormEmpresa />
          </Box>

          <Box mt={5} >
            <FormUser />
          </Box>

        </Template>
      </Container >
    );
  }
}

export default DadosEmpresa;
